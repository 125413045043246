<template>
  <ml-container>
    <ml-grid>
      <div class="tw-col-span-5" v-if="item.id">
        <div class="tw-flex tw-items-center tw-mb-8 tw-text-black">
          <router-link :to="{ name: 'vendors.calendar' }" class="tw-rounded-lg tw-text-current tw-mr-4 tw-flex tw-items-center tw-justify-center tw-bg-gray-n0 tw-h-8 tw-w-8">
            <chevron-left-icon class="tw-w-4 tw-h-4" stroke-width="1" />
          </router-link>
          <h1 class="tw-block tw-text-xl tw-leading-relaxed tw-font-semibold">
            {{$t('vendor.calendar_settings')}}
          </h1>
        </div>
        <v-form ref="form" v-model="valid" :lazy-validation="lazy" @submit.prevent="updateItem()">
          <!--
          <v-switch
          v-model="item.set_my_available_dates"
          label="Available dates"
          :persistent-hint=true
          hint="Set availability dates for customers. If this option is set to off, then all dates are available for customers. You still can in calendar set yours unavailable or available dates."

        ></v-switch>
        -->
          <div>
            <h3 class="tw-font-semibold tw-text-gray-n3 tw-mb-3">{{$t('vendor.calendar_settings_label')}}</h3>
            <v-radio-group
                v-model="item.set_my_available_dates"
                mandatory
            >
              <v-radio
                  :value="true"
                  color="#2d2d2d"
                  :ripple="false"
              >
                <template v-slot:label>
                  <div class="tw-text-sm tw-text-black tw-leading-relaxed">
                    <div class="tw-font-semibold tw-mb-1">{{$t('vendor.calendar_settings_date_unavailable_label')}}</div>
                    <div>{{$t('vendor.calendar_settings_date_unavailable_label1')}}</div>
                  </div>
                </template>

              </v-radio>
              <v-radio
                  :value="false"
                  color="#2d2d2d"
                  :ripple="false"
              >
                <template v-slot:label>
                  <div class="tw-text-sm tw-text-black tw-leading-relaxed">
                    <div class="tw-font-semibold tw-mb-1">{{$t('vendor.calendar_settings_date_available_label')}}</div>
                    <div>{{$t('vendor.calendar_settings_date_available_label1')}}</div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>

          <div>
            <h3 class="tw-font-semibold tw-text-gray-n3 tw-mb-3">{{$t('vendor.offering')}}</h3>
            <!--
              <v-switch
                  v-model="item.vendor_availability_check"
                  label="Vendor availability check"
                  :persistent-hint=true
                  hint="With this option availability dates setting is set to Vendor"
                  @change="item.products_availability_check=!item.products_availability_check"
                ></v-switch>
            -->
            <v-checkbox
                :ripple="false"
                color="#2d2d2d"
                v-model="item.vendor_availability_check"
                @change="item.products_availability_check=!item.products_availability_check"
            >
              <template v-slot:label>
                <div class="tw-text-sm tw-text-black tw-leading-relaxed tw-relative">
                  <div class="tw-font-semibold">{{$t('vendor.provide_one_service')}}</div>
                  <ml-pop-up class="tw-w-5 tw-h-5 tw-absolute tw--top-2 tw--right-2"
                             :title="getHelpData('vendor_availability_check')[0].question"
                             :description="getHelpData('vendor_availability_check')[0].answer"
                             stroke-width="2" />
                </div>
              </template>

            </v-checkbox>
            <!--
            <v-switch
              v-model="item.products_availability_check"
              label="Package availability check"
              :persistent-hint=true
              hint="With this option availability dates setting is set for each Package"
              @change="item.vendor_availability_check=!item.vendor_availability_check"
            ></v-switch>
            -->


            <v-checkbox
                :ripple="false"
                color="#2d2d2d"
                v-model="item.products_availability_check"
                @change="item.vendor_availability_check=!item.vendor_availability_check"
            >
              <template v-slot:label>
                <div class="tw-text-sm tw-text-black tw-leading-relaxed tw-relative">
                  <div class="tw-font-semibold">{{$t('vendor.provide_many_service')}}</div>
                  <ml-pop-up class="tw-w-5 tw-h-5 tw-absolute tw--top-2 tw--right-2"
                             :title="getHelpData('products_availability_check')[0].question"
                             :description="getHelpData('products_availability_check')[0].answer"
                             stroke-width="2" />
                </div>
              </template>

            </v-checkbox>
          </div>
          <div class="tw-mt-6">
            <router-link :to="{ name: 'vendors.calendar' }"
                         class="tw-mt-6 tw-mr-6 tw-px-8 tw-py-3 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
              Cancel
            </router-link>
            <button type="submit"
                    :disabled="!valid"
                    class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
              Save & Exit
            </button>
          </div>
        </v-form>
      </div>
    </ml-grid>
  </ml-container>
</template>


<script>

//import { debounce } from 'vue-debounce'
import MlGrid from "@/components/layout/MlGrid";
import MlContainer from "@/components/layout/MlContainer";
import { ChevronLeftIcon } from "vue-feather-icons";
import i18n from '@/i18n'
import MlPopUp from "@/components/help/MLPopUp";
export default {
  components: {MlContainer, MlGrid, ChevronLeftIcon,MlPopUp},
  middleware: ['auth', 'vendor'],
  metaInfo() {
    return {
      title: i18n.t('calendar'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendors/calendar/settings'),
        },
      ]
    }
  },
  data() {
    return {
      valid: true,
      lazy: true,
      loading: false,
      item: {
        id: null,
        vendor_availability_check: null,
        products_availability_check: null,
        set_my_available_dates: null,
      },
      helpData:[
        {
          id: 'vendor_availability_check',
          question: 'What does mean?',
          answer: '<br> “I can only book one wedding per day”' +
              '<br><br> Check this box if you can only provide your Goods & Services for one wedding per day. When one of your Goods & Services or Packages get booked, all others on that date become unavailable to book.',
        },
        {
          id: 'products_availability_check',
          question: 'What does  mean?',
          answer: '<br> “I can book more than one wedding per day”' +
              '<br><br> Check this box if you can provide your Goods & Services for more than one wedding per day. When one of your Goods & Services or Packages get booked, all others on that date are still available to book.',
        },
      ],
      errored: false,
      rules: {
        required_select: v => this.area_coverage_check === true ? false : !!(v && v.length) || 'Item is required',
      },
    }
  },
  mounted() {
    this.getItem();

  },
  methods: {
    getHelpData(id){
      return this.helpData.filter(item => item.id === id)
    },
    async updateItem() {

      await this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;

      let uri = '/api/vendor/settings/' + this.item.id;
      this.$http.patch(uri, this.item)
          .then((response) => {
            this.$toast.success(response.data.message, "Success");
            this.$router.push({name: 'vendors.calendar'})
          });
    },
    getItem() {
      this.loading = true;
      let uri = '/api/vendor/settings';
      this.$http.get(uri)
          .then(({data}) => {
            this.item.vendor_availability_check = data.data.vendor_availability_check;
            this.item.products_availability_check = data.data.products_availability_check;
            this.item.set_my_available_dates = data.data.set_my_available_dates;
            this.item.id = data.data.id;
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          }).finally(() =>
          this.loading = false
      )

    },

  }
}
</script>







